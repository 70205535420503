import {TenantInfo} from '../models/tenant.model';
import dayjs from "dayjs";
import {NUMBER_OF_FREE_TRIAL_DAYS} from './ui-constants';



export function calculateTrialDaysLeft(tenant: TenantInfo) {

    if (!tenant?.accountCreatedAt) {
      return 0;
    }

    const start = dayjs(tenant.accountCreatedAt.toMillis());

    const end = start.add(NUMBER_OF_FREE_TRIAL_DAYS, 'day');

    const today = dayjs();

    return end.diff(today,'day') + 1;
}


export function isTrialExpired(tenant:TenantInfo) {

  if (!tenant.accountCreatedAt) {
    return false;
  }

  const start = dayjs(tenant.accountCreatedAt.toMillis());

  const today = dayjs();

  return start.add(NUMBER_OF_FREE_TRIAL_DAYS, "day").isBefore(today);

}
